@import "assets/style/_index.scss";

















































































































.form-box {
    padding: $padding-big;
}

.submit {
    min-height: 0.9rem;
    margin-top: 0.5rem;
    width: 100%;
}
