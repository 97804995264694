@import "assets/style/_index.scss";








































































































































































































































































.form-box {
    padding: $padding-big;
}
.authCode {
    width: 200px;
    height: $formItemHeight;
    line-height: 0;
}

.footer-ctrl {
    padding: $padding $padding-big;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $color-primary;
    //font-size: $small * 100%;
}
